import React from 'react';
import PropTypes from 'prop-types';

const IconButton = ({ className, onClick, disabled, children }) => {
  return (
    <button className={ className } onClick={ onClick } disabled={ disabled }>
      {children}
    </button>
  )
}

IconButton.propTypes = {
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  children: PropTypes.object.isRequired,
}

export default IconButton;
