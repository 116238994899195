import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Search extends Component {
  state = {
    value: ''
  }

  handleChange = (e) => {
    this.setState({
      value: e.target.value
    });
  }

  onSubmit = (e) => {
    e.preventDefault();
    this.props.onSubmit(this.state.value);
  }

  render() {
    return (
      <form onSubmit={this.onSubmit}>
        <input
          type='text'
          value={this.state.value}
          onChange={this.handleChange}
          placeholder='Search...'
        />
      </form>
    );
  }
}

Search.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default Search;
