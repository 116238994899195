import React from 'react';
import PropTypes from 'prop-types';
import IconButton from './IconButton';
import {
  PlayIcon, PauseIcon, SkipNextIcon, SkipPreviousIcon, RepeatAllIcon, RepeatOneIcon
} from '../icons/icons'
import './style.css'

const Repeats = ({ repeatAll, repeatOne, onRepeatAll, onRepeatOne }) => {
  if (repeatAll) {
    return (
      <div onClick={ onRepeatAll }>
        <RepeatAllIcon className='activated' />
      </div>
    )
  } else if (repeatOne) {
    return (
      <div onClick={ onRepeatOne }>
        <RepeatOneIcon className='activated' />
      </div>
    )
  } else {
    return (
      <div onClick={ onRepeatAll }>
        <RepeatAllIcon className={ repeatAll && 'activated' } />
      </div>
    )
  }
}

const Controllers = ({ onPreviousTrack, onNextTrack, onTogglePlayPause, previousButtonDisabled,
  nextButtonDisabled, playing, onRandom, onRepeatAll, onRepeatOne,
  repeatAll, repeatOne }) => {
    return (
      <div className='controllers'>
        <IconButton className='previous' onClick={ onPreviousTrack } disabled={ previousButtonDisabled }>
          <SkipPreviousIcon />
        </IconButton>

        <IconButton className='play' onClick={ onTogglePlayPause }>
          { playing ? <PauseIcon /> : <PlayIcon /> }
        </IconButton>

        <IconButton className='next' onClick={ onNextTrack } disabled={ nextButtonDisabled }>
          <SkipNextIcon />
        </IconButton>

        <div className='repeats'>
          <Repeats
            repeatOne={ repeatOne }
            repeatAll={ repeatAll }
            onRepeatOne={ onRepeatOne }
            onRepeatAll={ onRepeatAll }
          />
        </div>
      </div>
    )
  }

Controllers.propTypes = {
  onPreviousTrack: PropTypes.func.isRequired,
  onNextTrack: PropTypes.func.isRequired,
  onTogglePlayPause: PropTypes.func.isRequired,
  previousButtonDisabled: PropTypes.bool.isRequired,
  nextButtonDisabled: PropTypes.bool.isRequired,
  playing: PropTypes.bool.isRequired,
  onRandom: PropTypes.func.isRequired,
  onRepeatAll: PropTypes.func.isRequired,
  onRepeatOne: PropTypes.func.isRequired,
  repeatAll: PropTypes.bool.isRequired,
  repeatOne: PropTypes.bool.isRequired,
}

export default Controllers;
