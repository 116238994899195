import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Tracks.css';
import Track from './Track';
import { Controllers } from '../controllers';
import { shuffle } from '../../util/helpers'

class Tracks extends Component {
  state = {
    playingTrackIndex: 0,
    nextButtonDisabled: false,
    previousButtonDisabled: true,
    playing: false,
    repeatAll: false,
    repeatOne: false,
  }

  componentWillReceiveProps() {
    this.reset();
  }

  disabledButtons = () => {
    const nextTrackIndex = this.state.playingTrackIndex
    this.setState({
      previousButtonDisabled: this.state.playingTrackIndex === 0,
      nextButtonDisabled: nextTrackIndex + 1 === this.props.tracks.length
    })
  }

  audioLoaded = () => {
    this.audio.play()
  }

  audioEnded = () => {
    let nextTrack = this.state.playingTrackIndex + 1
    if (this.state.repeatOne) {
      nextTrack = this.state.playingTrackIndex
    }

    if (nextTrack === this.props.tracks.length) {
      if (this.state.repeatAll) {
        nextTrack = 0
      } else {
        this.setState({ playing: false })
        return
      }
    }

    // if (this.state.random) {
    //   let tracksArray = []
    //   for (let i = 0; i <= this.props.tracks.legnth; i++) {
    //     tracksArray.push(i)
    //   }
    // }

    // let shuffledTracksArray = shuffle(tracksArray)

    this.setState({
      playingTrackIndex: nextTrack
    }, this.play)
  }

  play = () => {
    this.disabledButtons();

    if (this.audio) {
      this.stop()
    }

    this.audio = new Audio(this.props.tracks[this.state.playingTrackIndex].previewURL);
    this.audio.addEventListener('loadeddata', this.audioLoaded);
    this.audio.addEventListener('ended', this.audioEnded);
    if (this.audio) {
      this.setState({
        playing: true,
      })
    }
  }

  stop = () => {
    if (this.audio) {
      this.audio.removeEventListener('loadeddata', this.audioLoaded);
      this.audio.removeEventListener('ended', this.audioEnded);
      this.audio.pause();
    }

    this.setState({
      playing: false
    })

    this.audio = null;
  }

  togglePlayPause = () => {
    this.state.playing ? this.stop() : this.play()
    this.setState({
      playing: !this.state.playing
    })
  }

  nextTrack = () => {
    this.audioEnded()
  }

  previousTrack = () => {
    const previousTrack = this.state.playingTrackIndex - 1
    if (previousTrack === 0) { return }

    this.setState({
      playingTrackIndex: previousTrack
    }, this.play)
  }

  selectTrack = (trackId) => {
    const playingTrackIndex = this.props.tracks.findIndex((t) => t.id === trackId)
    this.setState({
      playingTrackIndex
    }, this.play)
  }

  reset = () => {
    this.stop();
    this.setState({
      playingTrackIndex: 0,
      playing: false
    })
  }

  random = () => {
    this.setState({
      random: true
    })
  }

  repeatAll = () => {
    const repeatAll = !this.state.repeatAll
    const repeatOne = !this.state.repeatOne

    if (this.state.repeatAll) {
      this.setState({
        repeatOne,
        repeatAll
      })
    } else {
      this.setState({
        repeatAll,
        repeatOne: false
      })
    }
  }

  repeatOne = () => {
    const repeatAll = false
    const repeatOne = !this.state.repeatOne
    this.setState({
      repeatOne,
      repeatAll
    })
  }

  render() {
    const { tracks } = this.props;

    return (
      <div>
        <div className='tracks'>
          {tracks.map((track, i) => (
            <Track
              track={ track }
              key={ track.id }
              playingTrack={ tracks[this.state.playingTrackIndex] }
              onSelectTrack={ this.selectTrack }
            />
          ))};
        </div>

        <Controllers
          onPreviousTrack={ this.previousTrack }
          onNextTrack={ this.nextTrack }
          onTogglePlayPause= { this.togglePlayPause }
          onRandom={ this.random }
          onRepeatAll={ this.repeatAll }
          onRepeatOne={ this.repeatOne }
          repeatAll={ this.state.repeatAll }
          repeatOne={ this.state.repeatOne }
          previousButtonDisabled={ this.state.previousButtonDisabled }
          nextButtonDisabled={ this.state.nextButtonDisabled }
          playing={ this.state.playing }
        />
      </div>
    )
  }
}

Tracks.propTypes = {
  tracks: PropTypes.array.isRequired,
}

export default Tracks;
