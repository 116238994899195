import React from 'react'

const dimension = '30'

export const PlayIcon = () => {
  return (
    <svg className='icon' xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox={`0 0 24 24`}>
      <path d="M8 5v14l11-7z" />
      <path d="M0 0h24v24H0z" fill="none" />
    </svg>
  )
}

export const PauseIcon = () => {
  return (
    <svg className='icon' xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24">
      <path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z" />
      <path d="M0 0h24v24H0z" fill="none" />
    </svg>
  )
}

export const SkipNextIcon = () => {
  return (
    <svg className='icon' xmlns="http://www.w3.org/2000/svg" width={dimension} height={dimension} viewBox="0 0 24 24">
      <path d="M6 18l8.5-6L6 6v12zM16 6v12h2V6h-2z" />
      <path d="M0 0h24v24H0z" fill="none" />
    </svg>
  )
}

export const SkipPreviousIcon = () => {
  return (
    <svg className='icon' xmlns="http://www.w3.org/2000/svg" width={dimension} height={dimension} viewBox="0 0 24 24">
      <path d="M6 6h2v12H6zm3.5 6l8.5 6V6z" />
      <path d="M0 0h24v24H0z" fill="none" />
    </svg>
  )
}

export const RepeatAllIcon = ({className}) => {
  return (
    <svg className={`icon repeat ${className}`} xmlns="http://www.w3.org/2000/svg" width={dimension} height={dimension} viewBox="0 0 24 24">
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M7 7h10v3l4-4-4-4v3H5v6h2V7zm10 10H7v-3l-4 4 4 4v-3h12v-6h-2v4z" />
    </svg>
  )
}

export const RepeatOneIcon = ({className}) => {
  return (
    <svg className={`icon repeat ${className}`} xmlns="http://www.w3.org/2000/svg" width={dimension} height={dimension} viewBox="0 0 24 24">
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M7 7h10v3l4-4-4-4v3H5v6h2V7zm10 10H7v-3l-4 4 4 4v-3h12v-6h-2v4zm-4-2V9h-1l-2 1v1h1.5v4H13z" />
    </svg>
  )
}
