import React, { Component } from 'react';
import './App.css';
import { fetchTopTracks, fetchGenres, fetchTrackForGenre, fetchTracksForSearch } from './util/ajax';
import { Tracks } from './components/tracks';
import { Select } from './components/genres';
import { Search } from './components/search';

class App extends Component {
  state = {
    tracks: [],
    genres: [],
  }

  async componentDidMount() {
    const { tracks } = await fetchTopTracks();
    const { genres } = await fetchGenres();
    this.setState({ tracks, genres });
  }

  handleSelectingGenre = async (genreId) => {
    const { tracks } = await fetchTrackForGenre(genreId);
    this.setState({ tracks });
  }

  handleSearch = async (term) => {
    const json = await fetchTracksForSearch(term);
    this.setState({
      tracks: json.search.data.tracks
    });
  }

  render() {
    return (
      <main>
        <header>
          <Select genres={ this.state.genres } onChange={ this.handleSelectingGenre } />
          <h1>Muzik</h1>
          <Search onSubmit={this.handleSearch} />
        </header>
        <Tracks tracks={ this.state.tracks } />
      </main>
    )
  }
}

export default App;
