const apiHost = 'https://api.napster.com/v2.2';
const apiKey = 'NjgwN2VkZTctMjMyMi00NTJlLWI5YzMtOTM3MjkxNThlOWQ4';

export const fetchTopTracks = async () => {
  try {
    const response = await fetch(`${apiHost}/tracks/top`, {
      headers: new Headers({ 'apiKey': apiKey })
    });
    const json = await response.json();
    return json;
  } catch(error) {
    console.log('ERROR: ', error);
  }
}

export const fetchGenres = async () => {
  try {
    const response = await fetch(`${apiHost}/genres`, {
      headers: new Headers({ 'apiKey': apiKey })
    });
    const json = await response.json();
    return json;
  } catch(error) {
    console.log('ERROR: ', error);
  }
}

export const fetchTrackForGenre = async (genreId) => {
  try {
    const response = await fetch(`${apiHost}/genres/${genreId}/tracks/top`, {
      headers: new Headers({ 'apiKey': apiKey })
    });
    const json = await response.json();
    return json;
  } catch(error) {
    console.log('ERROR: ', error);
  }
}

export const fetchTracksForSearch = async (term) => {
  try {
    const response = await fetch(`${apiHost}/search/verbose?query=${term}&type=track`, {
      headers: new Headers({ 'apiKey': apiKey })
    });
    const json = await response.json();
    return json;
  } catch(error) {
    console.log('ERROR: ', error);
  }
}
