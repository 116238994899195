import React from 'react';
import PropTypes from 'prop-types';

const Select = ({ genres, onChange }) => {
  return (
    <select onChange={(e) => onChange(e.target.value)}>
      <option>-- Select Genre --</option>
      {genres.map((genre) => (
        <option value={genre.id} key={genre.id}>{genre.name}</option>
      ))}
    </select>
  );
}

Select.propTypes = {
  genres: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default Select;
