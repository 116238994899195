import React from 'react';
import PropTypes from 'prop-types';
import './Tracks.css';

const imageUrl = 'https://direct.napster.com/imageserver/v2/albums';
const imageSize = 500;
const imageExtension = 500;

const Track = ({ track, playingTrack, onSelectTrack }) => {
  return (
    <div
      className={`track ${playingTrack.id === track.id && 'active'}`}
      onClick={ () => onSelectTrack(track.id) }
    >
      <img
        src={`${imageUrl}/${track.albumId}/images/${imageSize}x${imageExtension}.jpg`}
        alt={track.name}
      />
      <div className='info'>
        <div className='name'>{ track.name }</div>
        <div className='artist'>{ track.artistName }</div>
      </div>
    </div>
  )
}

Track.propTypes = {
  track: PropTypes.object.isRequired,
  playingTrack: PropTypes.object.isRequired,
  onSelectTrack: PropTypes.func.isRequired,
}

export default Track;
